import React from 'react';
import LandingPageTemplate from '../../components/LandingPageTemplate';
import mainImage from '../../images/calculating.webp';
import fotoanleitung from '../../images/wartungsvertrag_scan_vergleich.png';
import automatiktuer_wartung_techniker_01_1000 from '../../images/automatiktuer_wartung_techniker_01_1000.webp';
import plus from '../../images/plus.png';
import MaintenanceForm from "../../components/MaintenanceForm";


const context = {
  title: 'Wartungsvertrag vergleichen: Jetzt wechseln und sparen', // Große Haupt-Überschrift
  subTitle: 'Automatiktür Wartung', // Kleine Seiten-Überschrift über der Haupt-Überschrift
  description: 'Warum ist die Wartung meiner Automatiktür wichtig?',
  image: mainImage, //Haupt-Bild der Landingpage
  imageAlt: "", //Alternativtext / Beschreibung zum Haupt-Bild
  contactButtonText: "Kontaktformular",
  suggestedProductsTitle: '',
  suggestedProducts: [
  ],
  text:
    <div>

      <p className="pb-5">Auf Basis der deutschen bzw. europäischen Industrienormen (DIN 18650, EN 16005) sowie der technischen
        Regeln für Arbeitsstätten (ASR 1.7) ist eine wiederkehrende sicherheitstechnische Überprüfung von Automatiktüren
        durch einen Sachkundigen mindestens einmal jährlich erforderlich.
        Um einen technisch einwandfreien Betrieb sicherzustellen wird eine zweimal jährliche Wartung empfohlen.</p>

      <p className="pb-5">Speziell für automatische Türanlagen in Flucht- und Rettungswegen wird zur Sicherstellung der Notfunktion,
        auf Basis der Herstellervorgaben und der Norm DIN 18650 sowie EN 16005 eine zweimal jährliche Wartung empfohlen.</p>

      <p className="pb-5">Darüber hinaus helfen Ihnen die regelmäßige Wartungen mit UVV-Prüfung (Unfallverhütungsvorschriften)
        und Funktionsprüfungen Ausfälle zu vermeiden.
      </p>

      <h2 className="mt-8 lg:text-xl font-semibold">Warum lohnt es sich zu vergleichen?</h2>
      <p className="my-3">
        Wenn es um die Wartung Ihrer automatischen Türsysteme geht, verstehen wir, dass Sie nach einer zuverlässigen,
        kostenwirksamen und professionellen Serviceleistung suchen. Hier sind sechs Gründe, warum es sich lohnt,
        Ihren (bestehenden) Wartungsangebote zu vergleichen:
      </p>


      <ol className="list-decimal ml-8">
        <li className="mt-2"><b>Kostenoptimierung:</b> Durch den Vergleich verschiedener Angebote können Sie das beste Preis-Leistungs-Verhältnis
          für Ihre Wartungsbedürfnisse finden. Verschiedene Anbieter bieten unterschiedliche Preisstrukturen an, und ein
          Vergleich hilft Ihnen Geld zu sparen.</li>
        <li className="mt-2"><b>Servicequalität:</b> Die Qualität des angebotenen Services kann variieren oder manchmal über die Zeit auch abnehmen.
          Sie wollen einen Partner, der nicht nur Ihre automatischen Türen pflegt, sondern auch deren Langlebigkeit und Zuverlässigkeit sicherstellt.</li>
        <li className="mt-2"><b>Angepasste Wartungspläne:</b> Verschiedene Anbieter haben unterschiedliche Ansätze, wenn es um
          Wartungspläne geht. Finden Sie den Anbieter finden, der flexible und individuell
          abgestimmte Wartungspläne anbietet, die zu Ihren speziellen Öffnungszeiten und Anforderungen passt.</li>
        <li className="mt-2"><b>Reaktionszeit und Verfügbarkeit:</b> Im Falle einer Störung Ihrer automatischen Türsysteme ist es wichtig,
          dass Ihr Wartungspartner schnell und effektiv reagieren kann.</li>
        <li className="mt-2"><b>Regionalität und Nachhaltigkeit:</b> Finden Sie den passenden Anbieter in Ihrer Region um weite Anfhrt zu vermeiden.
          Das spart Geld und schont die Umwelt.</li>
        <li className="mt-2"><b>Kompetenz und Zertifizierungen:</b> Überprüfen Sie, ob die Anbieter notwendige Zertifizierungen und Standards erfüllen.
          Dies gibt Ihnen Sicherheit bezüglich der Kompetenz und Professionalität des Wartungsdienstes.</li>
      </ol>

      <h2 className="mt-16 lg:text-xl font-semibold">Warum sollte ich zu Automatiktür24 wechseln?</h2>
      <div className="lg:flex">
        <ul className="mt-3 ml-8 flex-1">
          <li className="flex items-center mb-1"><img src={plus} className="w-4 h-4 mr-2" alt="Vorteil" />Gut ausgebildete und zertifizierte Servicetechniker</li>
          <li className="flex items-center mb-1"><img src={plus} className="w-4 h-4 mr-2" alt="Vorteil" />Hersteller unabhängig - Unterstützung aller gängigen Fabrikate</li>
          <li className="flex items-center mb-1"><img src={plus} className="w-4 h-4 mr-2" alt="Vorteil" />Modernste Austattung &amp; Werkzeug</li>
          <li className="flex items-center mb-1"><img src={plus} className="w-4 h-4 mr-2" alt="Vorteil" />100% digitaler Servicebericht</li>
          <li className="flex items-center mb-1"><img src={plus} className="w-4 h-4 mr-2" alt="Vorteil" />Reaktionszeit und Verfügbarkeit</li>
          <li className="flex items-center mb-1"><img src={plus} className="w-4 h-4 mr-2" alt="Vorteil" />Konkurrenzfähige Preise</li>
          <li className="flex items-center mb-1"><img src={plus} className="w-4 h-4 mr-2" alt="Vorteil" />Langfristige Partnerschaft</li>
        </ul>

        <img src={automatiktuer_wartung_techniker_01_1000} alt="Wartung automatische Schiebetür durch Techniker auf Leiter" className="w-80 rounded-lg m-5" />
      </div>



      <h2 className="mt-16 lg:text-xl font-semibold">Wartungsvertrag abfotografieren und einfach vergleichen</h2>

      <div className="container pt-3">

        <div className="xl:flex pr-5">
          <div className="xl:w-1/2">
            <div className="bg-blue-50 rounded-lg p-3">
              <b>So funktioniert's:</b>
              <ol className="list-decimal ml-8">
                <li>Bestehenden Wartungsvertrag abfotorgafieren</li>
                <li>Foto hochladen</li>
                <li>Automatiktür24 Angebot erhalten</li>
                <li>Sparen</li>
              </ol>
            </div>
          </div>
          <div className="xl:w-1/2 flex justify-center items-center">
              <img src={fotoanleitung} className="rounded-lg border border-gray-300 w-64"
                   alt="Wartungsvertrag für Automatiktüren vergleichen und sparen"/>
          </div>
        </div>

        <div className="mt-6">
            <MaintenanceForm message="Bitte erstellen Sie ein Vergleichsangebot auf Basis des bestehenden Wartungsvertrags im Anhang."/>
        </div>

      </div>


      <div className="mt-16 bg-gray-50 rounded-2xl p-4">
        <div className="text-lg font-bold text-gray-900">Relevante Normen für die Wartung von automatischen Türsystemen</div>
        <ul className="list-disc mx-8 my-4">
          <li><b>ASR 1.7:</b> Technische Regeln für die Arbeitsstätte für Türen und Tore</li>
          <li><b>AutSchR:</b>  Richtlinie für automatische Schiebetüren in Rettungswegen</li>
          <li><b>EltVTR:</b>  Richtlinie für elektrische Verriegelungssysteme von Türen in Rettungswegen</li>
          <li><b>DIN EN 16005:</b>  Kraftbetätigte Türen, Nutzungssicherheit, Anforderungen und Prüfverfahren</li>
          <li><b>DIN 18650:</b>  Automatische Türsysteme</li>
        </ul>

      </div>

    </div>
}

const Page = () => {

  return(
    <LandingPageTemplate pageContext={context} />
  )
}

export default Page;
